import React from 'react';
import { StarIcon } from '@heroicons/react/solid';

function Testimonial() {
  return (
    <div className="relative bg-gray-50">
      <div className=" max-w-7xl mx-auto flex flex-col sm:flex-row py-20 justify-center items-center">
        <div className="flex-shrink-0 flex  pr-5 w-3/4 sm:w-1/2 lg:w-1/2 justify-center items-center">
          <StarIcon className="h-20 w-20 text-yellow-400" aria-hidden="true" />
          <StarIcon className="h-20 w-20 text-yellow-400" aria-hidden="true" />
          <StarIcon className="h-20 w-20 text-yellow-400" aria-hidden="true" />
          <StarIcon className="h-20 w-20 text-yellow-400" aria-hidden="true" />
          <StarIcon className="h-20 w-20 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="flex-shrink-0 flex px-5 w-full sm:w-1/2 lg:w-1/2 justify-center items-center">
          <blockquote>
            <div>
              <p className="text-2xl md:text-3xl font-medium text-gray-800">
                "Wes is a good listener, which means he takes the time to really
                understand your needs. I highly recommend Bailey Tech."
              </p>
            </div>
            <footer className="mt-3">
              <div className="flex items-center space-x-3">
                <div className="text-base font-medium text-gray-700">
                  Katherine Englander,
                  <span className="font-normal pl-2">
                    Human Resources Partners 4 Business
                  </span>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
