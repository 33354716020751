import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function HeroImage() {
  return (
    <div className="bg-white">
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0">
          <StaticImage
            src="../images/banner.jpg"
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Chill Coding"
            className="h-full w-full object-cover"
            layout="fullWidth"
            loading="eager"
          />
          <div
            className="absolute inset-0 bg-gray-500 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl tracking-tight text-white md:text-5xl lg:text-6xl">
            Bailey <span className="font-semibold text-gray-50">Tech</span>
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300 mx-auto">
            Helping Companies Grow with Digital Marketing and Modern Website
            Design.
          </p>
        </div>
      </div>
    </div>
  );
}
