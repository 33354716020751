import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Testimonial from '../components/testimonial';
import HeroImage from '../components/heroImage';

function IndexPage() {
  return (
    <Layout>
      <Seo title="Web Design & Digital Marketing" />
      <HeroImage />
      <main>
        <div className="relative bg-gray-100 py-10">
          <div className="w-full flex flex-col">
            <p className="text-center text-md md:text-lg font-semibold uppercase text-gray-500 tracking-wide pb-5">
              Trusted by Local Small Business Owners
            </p>

            <StaticImage
              src="../images/logo-bar.png"
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Owner Trusted Clients"
              className="w-11/12 lg:w-4/6 mx-auto"
            />
          </div>
        </div>

        <div className="relative bg-white pt-10 overflow-hidden">
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-semibold tracking-tight text-gray-500">
                      <span className="text-gray-500 font-light">What</span> We
                      Do
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Bailey Tech specializes in digital marketing and website
                      design services for small businesses across Southwestern,
                      Ontario.
                    </p>
                    <p className="mt-4 text-lg text-gray-500">
                      Many business owners struggle to market what they sell
                      online. We begin with listening to understand your
                      struggles and needs. So that we can create and execute
                      custom solutions that will attract customers online.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="lg:relative lg:h-full w-3/4 md:w-1/2 mx-auto lg:w-full">
                  <StaticImage
                    src="../images/wesbailey.jpg"
                    quality={95}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    layout="fullWidth"
                    alt="Owner Wes Bailey"
                    objectFit="fill"
                    loading="lazy"
                    imgClassName="w-full lg:absolute lg:right-0 lg:h-5xl lg:w-11/12 lg:max-w-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testimonial />
        {/* Alternating Feature Sections */}
        <div className="relative h-auto overflow-hidden text-gray-400">
          <div className="">
            <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense">
              <div className="px-4 pt-10 pb-5 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-6xl  lg:mx-0 lg:px-16 lg:col-start-2">
                <div>
                  <div className="mt-6 max-w-2xl">
                    <h2 className="text-3xl font-semibold tracking-tight text-gray-500">
                      <span className="text-gray-500 font-light">Digital </span>
                      Marketing
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      With so many digital marketing options (SEM, SEO, PPC)
                      it's easy to become confused.
                    </p>
                    <p className="mt-4 text-lg text-gray-500">
                      Bailey Tech works with your business by learning its'
                      marketing goals. Then recommending and implementing a
                      digital marketing campaign within your budget.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className=" -ml-48  md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <StaticImage
                    src="../images/digital-marketing.jpg"
                    quality={95}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Wes Bailey talking with Client"
                    layout="fullWidth"
                    className="w-full  lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="relative h-auto overflow-hidden text-gray-400 bg-gray-800">
            <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense ">
              <div className="px-4 pt-10 pb-5 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-6xl  lg:mx-0 lg:px-16 lg:col-start-1">
                <div>
                  <div className="my-12 max-w-2xl  ml-auto">
                    <h2 className="text-3xl font-semibold tracking-tight text-gray-100">
                      <Link
                        to="website-design-st-thomas"
                        alt="Web Design Service"
                      >
                        <span className="text-gray-100 font-light pr-2">
                          Web
                        </span>
                        Design & Development
                      </Link>
                    </h2>
                    <p className="mt-4 text-lg text-gray-100">
                      Today's tech savvy customers don't trust a business
                      without a website. A professional website is critical for
                      building credibility with your customers.
                    </p>
                    <p className="mt-4 text-lg text-gray-100">
                      Bailey Tech will turn your website vision into reality by
                      developing it with insight, skill and timeliness
                    </p>
                    <div className="mt-5">
                      <Link
                        to="/website-design-st-thomas"
                        className="inline-flex bg-gray-50  px-4 py-2  text-base font-medium rounded-md shadow-sm text-gray-900"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="">
                  <StaticImage
                    src="../images/listen-understand.jpg"
                    quality={95}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Wes Bailey talking with Client"
                    layout="fullWidth"
                    className="w-full  lg:right-0 lg:h-full lg:w-auto lg:max-w-none hidden lg:block"
                    objectFit="cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default IndexPage;
